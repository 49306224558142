.app-layer-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  opacity: .5;
  z-index: 998;
}

.app-layer {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #f1f9ff;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 20px);
  text-align: center;
  border: 1px solid #e5f0f9;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 80px 0 #0000003d;

  &, * {
    box-sizing: border-box;
  }

  width: 600px;
  &.size-sm { width: 400px; }
  &.size-lg { width: 800px; }
  &.size-full { width: calc(100vw - 60px); }

  .title {
    text-align: left;
    padding: 16px 20px;
  }

  .body {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 10px 30px;
  }

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 30px;
    grid-gap: 20px;

    button {
      border: none;
      cursor: pointer;

      &:hover {
        background: #cce0f3;
      }
    }

    .material-symbols-sharp {
      font-size: 40px !important;
      color: #000;
      font-variation-settings: "wght" 300;
    }
  }
}
