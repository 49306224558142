.preview {
    .symbol {
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    img {
        height: 70px;
    }

    .material-symbols-sharp {
        font-size: 70px !important;
    }

    .filename {
        font-size: 16px;
        margin-top: 10px;
    }
}
