.visualization {
  width: 100%;
  height: 5px;
  background: #efefef;

  .inner {
    background: #0076d7;
    height: 100%;
    transition: width .1s;
  }
}