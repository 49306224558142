.ringing {
  text-align: center;

  .buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button {
      border: none;
      cursor: pointer;
    }

    .material-symbols-sharp {
      font-size: 40px !important;
      color: #000;
      font-variation-settings: "wght" 300;
    }
  }
}
