@import './fonts/roboto/roboto';

@keyframes loading {
  0% { transform: rotate(0) }
  40% { transform: rotate(0) }
  50% { transform: rotate(180deg) }
  90% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}

.material-symbols-sharp {
  color: #4791db;
  font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' 0, 'opsz' 48;
  font-size: 50px !important;
}

* {
  font-family: 'Roboto', sans-serif;
}

.chat {
  display: flex;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 14px;

  &, * {
    box-sizing: border-box;
  }

  & > .left {
    max-width: 95vw;
    box-shadow: 0 0 12px 0 #dbdbdb;
    position: relative;
    transition: transform .35s;
    width: 230px;
    flex-shrink: 0;

    .status {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 12px;
      color: #9d9d9d;
      padding: 5px;
    }
  }

  .username {
    padding: 20px;
    margin-bottom: 20px;
  }

  .user-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li.user {
      background: #f1f9ff;
      padding: 15px 20px;
      display: flex;

      .badge, .username {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
      }

      .username {
        padding: 0 10px;
      }
    }
  }

  .badge {
    .inner {
      display: inline-block;
      border: 1px solid #4791db;
      color: #4791db;
      width: 30px;
      height: 30px;
      line-height: 28px;
      text-align: center;
      border-radius: 100%;
      background: #fff;
      font-weight: bold;
    }
  }

  & > .right {
    width: 100%;
    display: flex;
    flex-direction: column;

    & > header {
      width: 100%;
      height: 60px;
      padding: 40px 0;

      display: flex;
      justify-content: space-between;

      & > * {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      h1.title {
        font-size: 22px;
      }

      .close {
        .material-symbols-sharp {
          color: #000;
        }
      }

      a {
        text-decoration: none;
      }

      .left {
        padding-left: 20px;
      }

      .right {
        padding-right: 20px;

        .inner {
          display: flex;

          & > *:not(ul) {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }

  .messages {
    height: calc(100% - 50px);
    position: relative;
    overflow-x: hidden;

    &.loading {
      position: relative;

      .material-symbols-sharp {
        animation: loading 4s infinite;
        position: absolute;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        width: 50px;
        height: 50px;
      }
    }

    & > .inner {
      position: absolute;
      bottom: 0;
      overflow-y: auto;
      max-height: 100%;
      width: 100%;
      padding: 20px 20px 0;
    }

    & > .typing {
      position: absolute;
      bottom: -5px;
      left: 20px;
      font-size: 10px;
      color: #9d9d9d;
    }

    .message {
      display: flex;
      margin-bottom: 20px;

      & > .left {
        padding-right: 10px;
      }

      .badge {
        margin-right: 15px;
      }

      .meta {
        margin-bottom: 8px;

        .from {
          margin-bottom: 10px;
          font-weight: bold;
        }

        .timestamp {
          color: #9d9d9d;
          display: inline-block;
          margin-left: 10px;
        }
      }

      &:not(.self) {
        & > .inner {
          width: calc(100% - 45px);
        }
      }
    }

    .notice {
      color: #9d9d9d;
      font-size: 12px;
      margin-bottom: 10px;
    }
  }

  .file-list {
    &.is-sender {
      text-align: right;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: inline-block;
      width: 100%;

      li {
        width: 50%;
        background: #f1f9ff;
        text-align: center;
        font-size: 12px;
        color: #9d9d9d;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;

        img {
          width: 100%;
          margin-bottom: 10px;
        }

        &[data-mime^="image/"] {
          background: none;
          padding: 0;

          .filename {
            display: none;
          }

          img {
            border-radius: 6px;
          }
        }

        &.sign-info {
          width: 100%;
          padding: 20px;
          font-size: 16px;

          .material-symbols-sharp {
            font-size: 30px !important;
          }

          .button {
            margin-top: 20px;
            display: block;
            padding-left: 30px;
            padding-right: 30px;
            align-self: center;
          }
        }

        .symbol-signed {
          position: absolute;
          top: -5px;
          right: -20px;
          font-size: 40px;
          cursor: help;
        }
      }
    }
  }

  .compose {
    display: flex;
    width: 100%;
    position: relative;
    padding: 0 20px 20px;
    box-sizing: border-box;

    .inner {
      border: 1px solid #ced5da;
      width: 100%;
      border-radius: 8px;
    }

    .actions {
      display: flex;
      justify-content: space-between;
    }

    .material-symbols-sharp {
      color: #868f96;
      font-size: 30px !important;
    }

    .upload {
      display: flex;
    }

    .textarea {
      position: relative;
      flex-grow: 1;
      z-index: 1;

      textarea {
        padding: 16px 10px;
        width: 100%;
        border: none;
        border-radius: 8px 8px 0 0;

        &:focus {
          outline: none;
          border-color: #daf0fd;
        }
      }

      .drop-target {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #f1f9ff;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        color: #9d9d9d;
        opacity: 0;
        transition: all .5s;
        cursor: default;
        border: 3px dashed #b9e1ff;
        pointer-events: none;

        &.drag-over {
          border-color: #2ba6ff;
        }
      }
    }

    button {
      border: none;
      background: none;
      cursor: pointer;
      padding: 0 10px;

      &.disabled {
        cursor: default;

        span {
          color: #9d9d9d;
        }
      }
    }

    .upload-queue {
      position: absolute;
      bottom: 3px;
      padding-bottom: 60px;
      width: calc(100% );
      z-index: -1;
      box-shadow: 0 0 13px -3px #bdbdbd;
      border-radius: 10px 10px 0 0;

      .inner {
        background: #f1f9ff;
        border-radius: 10px 10px 0 0;
        padding: 15px 10px 0;

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          display: flex;
          overflow-y: auto;

          li {
            text-align: center;
            margin: 0 10px;
            padding-bottom: 15px;
            position: relative;
            white-space: nowrap;

            .preview {
              &.loading {
                opacity: .4;
              }

              a.delete {
                position: absolute;
                right: -3px;
                top: 0;
                z-index: 999;
                background: #000;
                border-radius: 30px;
                width: 24px;
                height: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                text-decoration: none;

                span {
                  font-size: 16px !important;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}

body.drag-over .compose .textarea .drop-target {
  opacity: 1;
  pointer-events: all;
}

body.drag-over .compose .textarea textarea {
  pointer-events: none;
}

a.nav-toggle {
  position: fixed;
  top: 23px;
  left: 20px;
  transform: translateY(-50%);
  width: 24px;
  height: 16px;
  display: none;
  z-index: 100;

  .center, &:before, &:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    transition: all .25s;
    opacity: 1;
  }

  .center {
    top: 50%;
    transform: translateY(-50%);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }
}

@media screen and (max-width: 560px) {
  body:not(.show-sidebar) {
    .chat {
      & > .left {
        transform: translateX(-100%);
        box-shadow: none;
      }
    }
  }

  body.show-sidebar {
    a.nav-toggle {
      .center {
        opacity: 0 !important;
      }

      &:before {
        top: 50% !important;
        transform: rotate(45deg) translateY(-50%);
      }
      &:after {
        bottom: 50% !important;
        transform: rotate(-45deg) translateY(50%);
      }
    }
  }

  .chat > .left {
    position: fixed;
    left: 0;
    top: 0;
    height: calc(100 * var(--vh));
    z-index: 10;
    background: #fff;
  }

  .chat > .left > header {
    padding-left: 40px;
  }

  a.nav-toggle {
    display: block;
  }
}

#start-own-video {
  position: absolute;
  top: 5px;
  right: 15px;
}

.video-container {
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: 210px;
  height: 130px;
  //transition: all .5s;

  video {
    &#own {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000;
      z-index: 999;
      cursor: pointer;
    }
  }

  .video-ctrl {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1000;
    width: 100%;
    text-align: center;

    span {
      color: #fff;
      font-size: 25px !important;
      //transition: all .5s;
    }
  }

  &.maximized {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);

    .video-ctrl  span {
      font-size: 60px !important;
    }
  }
}

.webrtc-container {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999;
  width: 90vw;
  height: 60vh;
  background: #fff;
  padding: 10px;
  box-shadow: 0 0 10px 0 #000;

  &, * {
    box-sizing: border-box;
  }

  video {
    background: #000;
    position: absolute;

    &#local {
      width: 160px;
      z-index: 2;
      top: 10px;
      right: 10px;
      //transition: all .5s;
    }
    &#remote {
      background: #000;
      bottom: 50px;
      width: calc(100% - 20px);
      height: calc(100% - 60px);
      z-index: 1;
      left: 10px;
    }
  }

  &:not(.stat-3) {
    video {
      &#local {
        width: calc(100% - 20px);
        height: calc(100% - 60px);
      }
      &#remote {
        display: none;
      }
    }
  }

  .buttons {
    position: absolute;
    bottom: 10px;
    display: flex;
    width: calc(100% - 20px);

    button {
      width: 33.33%;
      background: #000;
      color: #fff;
      border: none;
      padding: 10px 0;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }

      &:disabled {
        background: #ccc;
      }

      &:not(:disabled) {
        cursor: pointer;
      }
    }
  }
}

#video-chat {
  position: relative;
  background: #000;
  z-index: 99;
  overflow: hidden;
  flex-grow: 1;

  .debug {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    background: #fff;
    z-index: 9;
    display: none;
  }

  .local-video-wrapper, .remote-video-wrapper {
    position: relative;

    span {
      display: block;
      position: absolute;
      left: 10px;
      bottom: 60px;
      color: #fff;
      padding: 8px 15px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        background: #fff;
        width: 100%;
        height: 100%;
        opacity: .3;
        border-radius: 10px;
      }
    }
  }

  .video-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    .local-video-wrapper {
      position: absolute;
      right: 50px;
      top: 50px;
      width: 15%;
      z-index: 2;
    }

    .remote-video-wrapper {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    .screen-share-wrapper {
      position: absolute;
      left: 20px;
      top: 20px;
      width: 15%;
      z-index: 2;
      border-radius: 10px;
      padding: 3px;
      background: #fff;

      header {
        font-size: 9pt;
        padding: 3px 4px;
        display: flex;
        justify-content: space-between;

        a {
          font-weight: bold;
          text-decoration: none;
          color: #000;
        }

        span {
          display: block;

          &.material-symbols-sharp {
            font-size: 12pt !important;
            color: #000;
          }
        }
      }
    }

    video {
      //transition: all .3s;
      width: 100%;
      height: 100%;

      &.remote {
        z-index: 1;
        object-fit: cover;
      }

      &.local {
        border-radius: 15px;
        border: 3px solid #fff;
      }
    }
  }

  &:not(.has-remote) {
    .local-video-wrapper {
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;

      video {
        border: none;
        object-fit: cover;
        border-radius: 0;
      }
    }
  }

  .controls {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
    text-align: center;
    width: 100%;
    height: 80px;
    padding: 0 30px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #fff;
      opacity: .2;
      z-index: -1;
    }

    display: flex;
    justify-content: space-between;

    & > * {
      height: 100%;
    }

    @media screen and (max-width: 700px) {
      padding: 0 15px;
      height: 60px;

      button {
        height: 40px;
        width: 40px;
        margin: 8px 5px 0;

        span {
          font-size: 20px !important;
        }
      }
    }
  }
}

button {
  &.green {
    background: #0f0 !important;
  }
  &.red {
    background: #f00 !important;
  }

  &.hover-green:hover {
    background: #0f0 !important;
    span { color: #fff !important }
  }
  &.hover-red:hover {
    background: #f00 !important;
  }
}

.App.video-shown {
  .chat > .right {
    width: 400px;
    max-width: 100vw;
  }
  &:not(.sidebar-right) {
    .burger-toggle-right .burger-menu {
      &:before, .inner, &:after {
        background: #fff;
      }
    }
  }
}

.burger-toggle-right {
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 999;
}

@media screen and (max-width: 430px) {
  .compose {
    span.material-symbols-sharp {
      font-size: 30px !important;
    }

    textarea {
      font-size: 12px;
    }
  }
}

.buttons button, .buttons > a, .button {
  padding: 10px;
  font-size: 16px;
  background: #e3f1ff;
  border: none;
  cursor: pointer;
  color: #000;
  text-decoration: none;

  &.primary {
    padding: 15px 50px;
  }

  &:hover {
    background: #cce0f3;
  }
}
