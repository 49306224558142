button.action-button {
  height: 48px;
  width: 48px;
  border: none;
  cursor: pointer;
  padding: 0 12px;
  margin: 16px 8px 0;
  background: transparent linear-gradient(123deg, #1976D2 0%, #0D3B69 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0 0 6px #00000033;
  border-radius: 100px;
  position: relative;

  &.green, &.green:hover {
    background: transparent linear-gradient(123deg, #19d274 0%, #095629 100%) 0% 0% no-repeat padding-box !important;
  }

  &.red, &.red:hover {
    background: transparent linear-gradient(123deg, #d21919 0%, #560909 100%) 0% 0% no-repeat padding-box !important;
  }

  &.disabled {
    pointer-events: none;
    opacity: .5;
  }

  span {
    font-size: 24px !important;
    color: #fff;
    font-variation-settings: 'wght' 300;
  }

  i.counter, i.warning {
    position: absolute;
    right: -5px;
    top: -5px;
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 10px;
    font-style: normal;
    font-size: 12px;
    font-family: sans-serif;
    line-height: 18px;
  }

  i.counter {
    background: #1976D2;
  }

  i.warning {
    background: #FF4500;
  }
}