.lobby-container {
  header {
    height: 70px;
    text-align: center;
    display: flex;
    justify-content: center;

    .inner {
      width: 100vw;
      max-width: 1200px;
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;

      .left, .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .logo svg {
      height: 30px;
      width: 98px;
    }
  }
}

.lobby {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - 70px);

  & > .inner {
    display: flex;

    width: 100vw;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px 20px;
    box-sizing: border-box;
  }

  .left, .right {
    flex-grow: 0;
    flex-shrink: 0;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  select, input {
    padding: 10px 10px;
    border: 1px solid #ced6db;
    background: none;
    font-size: 14px;

    &:disabled {
      background: #efefef;
    }
  }

  .left {
    .preview-video-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 70%;
      overflow: hidden;
      background: #212629;
      color: #fff;
      font-weight: bold;
      font-size: 24px;

      span {
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
        z-index: 0;
      }

      video {
        position: absolute;
        top: 0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
        z-index: 1;
      }

      ul.buttons {
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: -25px;
        left: 0;
        width: 100%;
        z-index: 2;
        text-align: center;
        opacity: .3;
        transition: all .3s;
        display: flex;
        justify-content: center;

        li {
          display: inline-block;
          height: 48px;

          button {
            margin-top: 0;
          }
        }
      }
    }

    .preview-video-container, video, ul.controls {
      border-radius: 10px;
    }

    .preview-video-container, ul.controls {
      box-shadow: 0 0 10px 0 #ccc;
    }

    ul.controls {
      list-style-type: none;
      margin: 32px 0 -120px;
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;
      transform: translateY(-100%);
      opacity: 0;
      transition: all .3s;

      li {
        display: inline-block;

        label {
          display: block;
          font-size: 16px;
          margin-bottom: 8px;
          font-weight: 500;
        }

        select {
          width: 100%;
          overflow-y: hidden;
        }
      }
    }
  }

  .right {
    padding: 0 50px 0 10%;

    label {
      display: block;
      font-size: 24px;
      margin-bottom: 16px;
      font-weight: 500;
    }

    select, input, pre {
      width: 400px;
      max-width: 100%;
      box-sizing: border-box;
    }

    pre {
      overflow-y: auto;
    }

    .box:not(:last-child) {
      margin-bottom: 48px;
    }

    .buttons {
      button {
        margin-bottom: 16px;

        &:not(:last-child) {
          margin-right: 16px;
        }

        &:disabled {
          pointer-events: none;
          opacity: .5;
        }
      }
    }
  }

  button, .btn {
    background: none;
    border: 1px solid #1976D2;
    color: #0076d7;
    border-radius: 30px;
    padding: 16px 32px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;

    &:hover {
      background: #0076d7;
      color: #fff;
    }
  }

  .left .preview-video-container:hover,
  &.controls .left .preview-video-container,
  &.permission-denied .left .preview-video-container
  {
    .buttons {
      opacity: 1;
      bottom: 16px;
    }
  }

  &.controls .left ul.controls {
    transform: none;
    opacity: 1;
    margin-bottom: 0;
  }

  @media screen and (max-width: 700px) {
    justify-content: flex-start;

    .inner {
      flex-wrap: wrap;
    }

    .left, .right {
      width: 100%;
    }

    .right {
      padding: 0;

      label {
        margin-top: 30px;
      }

      input {
        width: 100%;
      }

      .buttons {
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 550px) {
    button.action-button {
      height: 40px;
      width: 40px;

      span {
        font-size: 22px;
      }
    }
    .right .buttons button {
      padding: 13px 24px;
    }
    .left .preview-video-container:hover .buttons, &.controls .left .preview-video-container .buttons {
      bottom: 5px !important;
    }
  }

  @media screen and (max-width: 440px) {
    .left ul.controls {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: -200px;

      li:nth-child(1) {
        grid-column-start: span 2;
      }
    }
  }

  @media screen and (max-width: 330px) {
    .left ul.controls {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 1fr);
      margin-bottom: -300px;

      li {
        grid-column-start: 1;
      }
    }

    .right .buttons {
      display: flex;
      flex-direction: column;

      button {
        margin-right: 0 !important;
      }
    }
  }
}