ul.lang {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin-right: 20px;
  white-space: nowrap;

  li {
    display: inline;

    a {
      text-decoration: none;
      color: #000;
      display: inline-block;
      padding: 0 3px;
    }

    &:not(:last-child) a {
      border-right: 1px solid #9d9d9d;
    }

    &.active a {
      color: #4791db;
    }
  }
}